import { MathJaxContext } from 'better-react-mathjax';
import React from 'react';
import { useSelector } from 'react-redux';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { useIntl } from 'react-intl';

import { AuthProvider } from 'context/AuthContext';
import { UserProvider } from 'context/UserContext';
import { CourseTreeProvider } from 'context/CourseTreeContext';
import ErrorBoundary from 'app/ErrorBoundary';
import { Routes } from 'app/Routes';
import { isFetchingUser } from 'selectors/user';
import AppLoadingPage from 'components/pages/AppLoadingPage';
import { UnsavedChangesProvider } from 'context/UnsavedChangesContext';
import { EquationModalProvider } from 'context/EquationModalContext';

const DescriptionMeta = () => {
  const { formatMessage: f } = useIntl();

  return (
    <Helmet>
      <meta name="description" content={f({ id: 'meta.description' })} />
    </Helmet>
  );
};

const AppWithProviders = () => {
  const isLoading = useSelector(isFetchingUser);

  const mathjaxConfig = {
    options: {
      enableMenu: false,
    },
    loader: {
      load: ['[tex]/html', '[tex]/cancel', '[tex]/upgreek', '[tex]/unicode'],
    },
    displayAlign: 'left',
    messageStyle: 'none',
    tex: {
      packages: { '[+]': ['html', 'upgreek', 'unicode'] },
      inlineMath: [['[%', '%]']],
      displayMath: [['[[%', '%]]']],
      processEnvironments: true,
    },
    'HTML-CSS': {
      scale: 100,
      mtextFontInherit: true,
      linebreaks: {
        automatic: true,
        width: 'container',
      },
    },
    SVG: {
      linebreaks: {
        automatic: true,
      },
    },
  };

  return (
    <MathJaxContext config={mathjaxConfig} version={3}>
      <HelmetProvider>
        <ErrorBoundary>
          <DescriptionMeta />
          <AuthProvider>
            <UserProvider>
              <CourseTreeProvider>
                <UnsavedChangesProvider>
                  <EquationModalProvider>
                    {isLoading ? <AppLoadingPage /> : <Routes />}
                  </EquationModalProvider>
                </UnsavedChangesProvider>
              </CourseTreeProvider>
            </UserProvider>
          </AuthProvider>
        </ErrorBoundary>
      </HelmetProvider>
    </MathJaxContext>
  );
};

export default AppWithProviders;
