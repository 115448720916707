import React from 'react';
import RcTooltip from 'rc-tooltip';
import classNames from 'classnames';

// use lib default styles and override some things
import 'rc-tooltip/assets/bootstrap_white.css';
import './Tooltip.css';

interface Props {
  children: React.ReactElement;
  text: string | React.ReactElement;
  placement?: 'top' | 'bottom' | 'left' | 'right' | 'bottomLeft';
  className?: string;
  customClassName?: string;
  showArrow?: boolean;
  trigger?: string[];
  defaultVisible?: boolean;
  isAbleToHover?: boolean;
  isMatrixPopover?: boolean;
  showMatrixPopover?: boolean;
}

const Tooltip = ({
  text,
  placement = 'top',
  showArrow,
  trigger,
  customClassName,
  defaultVisible,
  isAbleToHover,
  isMatrixPopover,
  showMatrixPopover,
  ...props
}: Props) => {
  if (isMatrixPopover) {
    return (
      <RcTooltip
        {...props}
        showArrow={false}
        placement={'top'}
        trigger={['click']}
        overlay={<span>{text}</span>}
        overlayClassName={classNames(customClassName)}
        mouseLeaveDelay={0.1}
        destroyTooltipOnHide={true}
        visible={showMatrixPopover}
        defaultVisible={false}
      />
    );
  }

  return (
    <RcTooltip
      {...props}
      showArrow={showArrow ?? false}
      placement={placement}
      trigger={trigger ?? ['hover']}
      overlay={<span>{text}</span>}
      overlayClassName={classNames(customClassName)}
      mouseLeaveDelay={isAbleToHover ? 0.1 : 0}
      defaultVisible={defaultVisible ?? false}
    />
  );
};

export default Tooltip;
