import React from 'react';

type Props = {
  isAnyEquationModalOpen: boolean;
  setIsAnyEquationModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  callbackFunctions: (() => void)[];
  setCallbackFunctions: React.Dispatch<React.SetStateAction<(() => void)[]>>;
  selectedLatex: string;
  setSelectedLatex: React.Dispatch<React.SetStateAction<string>>;
  selectedLatexPosition: string;
  setSelectedLatexPosition: React.Dispatch<React.SetStateAction<string>>;
};

const EquationModalContext = React.createContext<Props | undefined>(undefined);

const EquationModalProvider = (props: { children: React.ReactNode }) => {
  const [isAnyEquationModalOpen, setIsAnyEquationModalOpen] = React.useState(
    false,
  );
  const [callbackFunctions, setCallbackFunctions] = React.useState<
    (() => void)[]
  >([]);

  const [selectedLatex, setSelectedLatex] = React.useState('');
  const [selectedLatexPosition, setSelectedLatexPosition] = React.useState('');

  const value = React.useMemo(
    () => ({
      isAnyEquationModalOpen,
      setIsAnyEquationModalOpen,
      callbackFunctions,
      setCallbackFunctions,
      selectedLatex,
      setSelectedLatex,
      selectedLatexPosition,
      setSelectedLatexPosition,
    }),
    [
      isAnyEquationModalOpen,
      callbackFunctions,
      selectedLatex,
      selectedLatexPosition,
    ],
  );

  return <EquationModalContext.Provider value={value} {...props} />;
};

const useEquationModal = () => {
  const context = React.useContext(EquationModalContext);
  if (context === undefined) {
    throw new Error(
      `useEquationModal must be used within EquationModalProvider`,
    );
  }
  return context;
};

export { EquationModalProvider, useEquationModal };
