import React from 'react';
import ReactModal from 'react-modal';
import classNames from 'classnames';

import { ReactComponent as CloseIcon } from 'assets/icons/close.svg';
import Alert from 'components/atoms/Alert';

import './Modal.css';

// Hide the application from screenreaders and other assistive technologies
// while the modal is open
// See http://reactcommunity.org/react-modal/examples/set_app_element.html
if (process.env.NODE_ENV !== 'test') {
  ReactModal.setAppElement('#root');
} else {
  ReactModal.setAppElement(document.createElement('div'));
}

const ModalTitle = ({
  title,
  overline,
  subtitle,
  rightTitle,
  onClose,
}: {
  title?: string;
  overline?: string;
  subtitle?: string;
  rightTitle?: string;
  onClose?: () => void;
}) => {
  return (
    <div className="flex flex-shrink-0 justify-between font-bold text-16 py-16 pl-24 pr-16 border-b border-grey-minus2">
      <div className="flex flex-col max-w-full">
        {overline && (
          <span className="font-bold text-12 text-grey-plus1 mb-8 truncate">
            {overline}
          </span>
        )}
        {title}
        {subtitle && (
          <span className="font-normal text-10 text-grey-plus1 truncate">
            {subtitle}
          </span>
        )}
      </div>
      {rightTitle && (
        <div className="flex justify-end self-center">
          <span className="text-grey-plus1">{rightTitle}</span>
        </div>
      )}
      {onClose && (
        <span
          onClick={onClose}
          onKeyDown={e => {
            if (e.key === 'Enter') {
              onClose();
            }
          }}
          className="text-grey-plus2 cursor-pointer"
          data-testid="modal-close-button"
        >
          <CloseIcon width="20" height="20" className="inline fill-current" />
        </span>
      )}
    </div>
  );
};

const Modal = ({
  children,
  isOpen,
  title,
  overline,
  subtitle,
  rightTitle,
  onClose,
  className,
  errorMessage,
  isBottomRightModal = false,
  shouldReturnFocusAfterClose = true,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  title?: string;
  overline?: string;
  subtitle?: string;
  rightTitle?: string;
  onClose?: () => void;
  className?: string;
  errorMessage?: string;
  isBottomRightModal?: boolean;
  shouldReturnFocusAfterClose?: boolean;
}) => {
  return (
    <ReactModal
      shouldReturnFocusAfterClose={shouldReturnFocusAfterClose}
      isOpen={isOpen}
      bodyOpenClassName={classNames(
        isBottomRightModal ? 'overflow-y-hidden' : 'overflow-hidden',
      )}
      overlayClassName={classNames(
        'overflow-y-auto',
        isBottomRightModal ? '' : 'fixed inset-0  bg-modalOverlay',
      )}
      className={classNames(
        ' absolute  max-w-full flex flex-col justify-center items-center focus:outline-none',
        isBottomRightModal
          ? 'p-0 min-w-fit min-h-fit bottom-0 right-0'
          : 'px-32 py-40 min-w-screen min-h-screen',
      )}
    >
      <div
        data-testid="modal"
        className={classNames(
          'flex flex-col bg-white ',
          isBottomRightModal
            ? 'rounded-t-8 shadow-equationModal'
            : 'rounded-6 shadow-modal min-w-568 min-h-160',
          className,
        )}
      >
        {title && (
          <ModalTitle
            title={title}
            overline={overline}
            subtitle={subtitle}
            rightTitle={rightTitle}
            onClose={onClose}
          />
        )}
        {children}
      </div>

      {errorMessage && (
        <Alert className="min-w-568 mt-24 text-center">{errorMessage}</Alert>
      )}
    </ReactModal>
  );
};

export default Modal;
